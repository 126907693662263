import { useState, useEffect } from "react";
import "./AddCargo.css"
import { useNavigate } from "react-router-dom";
import axios from "axios";

function AddCargo({ sidebarOpen, setSidebarOpen, user, setUser }){

    const navigate = useNavigate()

    const [reciever, setReciever] = useState('');
    const [selectedStorage, setSelectedStorage] = useState('all')
    const [phone, setPhone] = useState('')
    const [transport, setTransport] = useState('')
    const [count, setCount] = useState('')
    const [price, setPrice] = useState('')
    const [debt, setDebt] = useState('')
    const [comment, setComment] = useState([])
    const [prepaid, setPrepaid] = useState({
        cash: 0,
        bank_card: 0,
        transfer: 0,
        kaspi: 0
    })

    const [storages, setStorages] = useState([])
    const [comments, setComments] = useState([])

    const addCargo = () => {
        const username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');
    
        // Function to generate track_number based on the current date and time
        const generateTrackNumber = () => {
            const now = new Date();
            const day = String(now.getDate()).padStart(2, '0');
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
            const year = String(now.getFullYear()).slice(-2); // Last two digits of the year
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
    
            return `${day}${month}${year}${hours}${minutes}${seconds}`;
        };
    
        if (username && hash) {
            // Generate track number using the current date and time
            const trackNumber = storages.find(storage => storage.id === selectedStorage)?.slug + generateTrackNumber();
    
            // Make the API call to add cargo
            axios.get('https://loraandrey.kz/api/add_cargo.php', {
                params: {
                    username: username,
                    hash: hash,
                    reciever: reciever,
                    storage_id: selectedStorage,
                    phone: phone,
                    transport: transport,
                    count: count,
                    price: price,
                    debt: debt,
                    comment: comment.join(', '),
                    prepaid: JSON.stringify(prepaid),
                    track_number: trackNumber
                }
            }).then(response => {
                const data = response.data;
                console.log(data)
                if (data.status === 'success') {
                    navigate('/admin/cargo');
                } else {
                    navigate('/auth');
                }
            })
            .catch(error => {
                navigate('/auth');
            });
        } else {
            navigate('/auth');
        }
    };

    useEffect(() => {
        const username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');

        if(username && hash){

            // get user
            axios.get('https://loraandrey.kz/api/me.php', {
                params: {
                    username: username,
                    hash: hash,
                }
            }).then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setUser(data.user)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

            // get comments
            axios.get('https://loraandrey.kz/api/get_comments.php')
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setComments(data.comments)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

            // get storages
            axios.get('https://loraandrey.kz/api/get_storages.php')
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setSelectedStorage(data.storages[0]['id'])
                    console.log(data.storages[0]['id'])
                    setStorages(data.storages)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

        }else {
            navigate('/auth')
        }
    }, [])

    return(
        <div className={sidebarOpen ? "CargoContainer" : "CargoContainer fullwidth"}>
            <div className="Header">
                <div className="HeaderTitleAndButtons">
                    <div className="HeaderLeft">
                        {!sidebarOpen && 
                            <button onClick={() => setSidebarOpen(!sidebarOpen)} className="SidebarButton">
                                <svg style={{marginLeft: '-8px'}} width="24px" height="24px" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 7C4 6.44771 4.44772 6 5 6H24C24.5523 6 25 6.44771 25 7C25 7.55229 24.5523 8 24 8H5C4.44772 8 4 7.55229 4 7Z" fill="#000000"/><path d="M4 13.9998C4 13.4475 4.44772 12.9997 5 12.9997L16 13C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15L5 14.9998C4.44772 14.9998 4 14.552 4 13.9998Z" fill="#000000"/><path d="M5 19.9998C4.44772 19.9998 4 20.4475 4 20.9998C4 21.552 4.44772 21.9997 5 21.9997H22C22.5523 21.9997 23 21.552 23 20.9998C23 20.4475 22.5523 19.9998 22 19.9998H5Z" fill="#000000"/>
                                </svg>
                            </button>
                        }
                        <h2 className="HeaderTitle">Добавить груз</h2>
                    </div>
        
                    <div className="Buttons">
                        <button onClick={addCargo} className="HeaderButton">
                            <p>Добавить</p>
                        </button>
                    </div>
                </div>
            </div>

            <div className="AddCargoDiv">
                <h3 className="ParametersTitle">Обшая информация</h3>
                <div className="ParametersDiv">
                    <div className="Parameter">
                        <label className="ParameterLabel">Получатель*</label>
                        <input value={reciever} onChange={e => setReciever(e.target.value)} type="text" className="ParameterInput" />
                    </div>
                    <div className="Parameter">
                        <label className="ParameterLabel">Телефон</label>
                        <input value={phone} onChange={e => setPhone(e.target.value)} type="text" className="ParameterInput" />
                    </div>
                    <div className="Parameter">
                        <label className="ParameterLabel">Машина*</label>
                        <input value={transport} onChange={e => setTransport(e.target.value)} type="text" className="ParameterInput" />
                    </div>
                    <div className="Parameter">
                        <label className="ParameterLabel">Количество*</label>
                        <input value={count} onChange={e => setCount(e.target.value)} type="text" className="ParameterInput" />
                    </div>
                    <div className="Parameter">
                        <label className="ParameterLabel">Цена</label>
                        <input value={price} onChange={e => setPrice(e.target.value)} type="text" className="ParameterInput" />
                    </div>
                    <div className="Parameter">
                        <label className="ParameterLabel">Долг</label>
                        <input value={debt} onChange={e => setDebt(e.target.value)} type="text" className="ParameterInput" />
                    </div>
                    <div className="Parameter">
                        <label className="ParameterLabel">Склад</label>
                        <select type="text" value={selectedStorage} onChange={e => setSelectedStorage(e.target.value)} className="ParameterInput" >
                            {
                                storages.map((storage, index) => (
                                    <option key={storage.title} value={storage.id}>{storage.title}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <h3 className="ParametersTitle">Комментарий</h3> 
                <div className="ParametersDiv">
                    {comments.map((item, index) => (
                        <div key={index} className="Comment">
                        <input
                            type="checkbox"
                            className="ParameterInput"
                            checked={comment.includes(item.name)}
                            onChange={e => {
                            var newComment = [...comment];
                            if (e.target.checked) {
                                newComment.push(item.name); // Add value to the array
                            } else {
                                newComment = newComment.filter(
                                new_comment => new_comment !== item.name
                                );
                            }
                            setComment(newComment); // Update state
                            }}
                        />
                        <label className="ParameterLabel">{item.name}</label>
                        </div>
                    ))}
                </div>
                <h3 className="ParametersTitle">Оплата в Алмате</h3>
                <div className="ParametersDiv">
                    <div className="Parameter">
                        <label className="ParameterLabel">Наличные</label>
                        <input value={prepaid.cash} type="number" onChange={e => setPrepaid({cash: parseFloat(e.target.value), bank_card: prepaid.bank_card, transfer: prepaid.transfer, kaspi: prepaid.kaspi})} className="ParameterInput" />
                    </div>
                    <div className="Parameter">
                        <label className="ParameterLabel">Банковская карта</label>
                        <input value={prepaid.bank_card} type="number" onChange={e => setPrepaid({cash: prepaid.cash, bank_card: parseFloat(e.target.value), transfer: prepaid.transfer, kaspi: prepaid.kaspi})} className="ParameterInput" />
                    </div>
                    <div className="Parameter">
                        <label className="ParameterLabel">Перевод</label>
                        <input value={prepaid.transfer} type="number" onChange={e => setPrepaid({cash: prepaid.cash, bank_card: prepaid.bank_card, transfer: parseFloat(e.target.value), kaspi: prepaid.kaspi})} className="ParameterInput" />
                    </div>
                    <div className="Parameter">
                        <label className="ParameterLabel">Каспи</label>
                        <input value={prepaid.kaspi} type="number" onChange={e => setPrepaid({cash: prepaid.cash, bank_card: prepaid.bank_card, transfer: prepaid.transfer, kaspi: e.target.value})} className="ParameterInput" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCargo
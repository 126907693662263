import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Auth.css';

function Auth({ setUser }){

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.get('https://loraandrey.kz/api/login.php', {
            params: {
                username: username,
                password: password
            }
        })
        .then(response => {
            const data = response.data;

            if (data.status === 'success') {
                localStorage.setItem('username', data.user.username);
                localStorage.setItem('hash', data.user.hash);
                setUser(data.user)
                navigate('/admin/cargo');
            } else {
                setError(data.message);
            }
        })
        .catch(error => {
            setError('Произошла ошибка при попытке входа.');
        });
    };

    return(
        <div className="AuthScreenContainer">
            <div className='AuthContainer'>
                <h1 className='AuthTitle'>Войдите, чтобы продолжить работу.</h1>
                <form className="AuthForm" onSubmit={handleSubmit}>
                    <label>Введите логин</label>
                    <input 
                        type="text" 
                        placeholder='Логин'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)} 
                    />
                    <label>Введите пароль</label>
                    <input 
                        type="password" 
                        placeholder='Пароль'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)} 
                    />
                    <p style={{color: 'red'}}>{error}</p>
                    <button type="submit">Войти</button>
                </form>
            </div>
            <div className='InfoContainer'>

            </div>
        </div>
    );
}

export default Auth;

import { useState, useEffect } from "react"
import axios from "axios"
import "./Main.css"

function Main(){
    const [faq, setFaq] = useState([])
    const [selectedFaqId, setSelectedFaqId] = useState(null)
    const [trackNumber, setTrackNumber] = useState('')
    const [output, setOutput] = useState({})

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth' // Smooth scroll
        });
    };

    const track_cargo = () => {
        if(trackNumber.length > 4){
            axios.get('https://loraandrey.kz/api/get_cargo_status.php', {
                params: {
                    track_number: trackNumber
                }
            })
            .then(response => {
                const data = response.data;
                setOutput(data);
            })
            .catch(error => {
                console.error('Error fetching cargo status:', error);
            });
        }
    };

    useEffect(() => {
        axios.get('https://loraandrey.kz/api/faq.php', {})
        .then(response => {
            const data = response.data;

            if (data.status === 'success') {
                setFaq(data.faq)
            }
        })
    }, [])

    return(
        <div className="Main">
            <h1 className="MainTitle">Надежная доставка грузов:<br />Алматы – Астана, быстро и безопасно!</h1>
            <div className="MainInfoDiv">
                <p className="MainInfo">Мы продолжаем следовать нашей миссии, обеспечивая надежную доставку грузов между Алматы и Астаной, каждый день доказывая свою преданность качеству и своевременности.</p>
            </div>
            <div className="MainButtonDiv">
                <button onClick={scrollToBottom} className="MainButton">
                    Отслеживать мой груз
                </button>
            </div>
            <img src="/MainPicture.png" className="MainPicture" alt="Main Picture" />
            <p className="MainCount">Более <span>500.000</span> клиентов</p>
            <div className="MainPricesDiv">
                <h2 className="MainPricesTitle">Прозрачные и доступные цены на<br />грузоперевозки: никаких скрытых затрат</h2>
                <div className="MainPricesSections">
                    <div className="MainPricesSection">
                        <img src="/1.png" className="MainPricesSectionImage" />
                        <p className="MainPricesSectionInfo">Ваш груз — в надёжных руках</p>
                    </div>
                    <div className="MainPricesSection">
                        <img src="/2.png" className="MainPricesSectionImage" />
                        <p className="MainPricesSectionInfo">Бережная перевозка с вниманием к деталям</p>
                    </div>
                    <div className="MainPricesSection">
                        <img src="/3.png" className="MainPricesSectionImage" />
                        <p className="MainPricesSectionInfo">Доставим ваш груз быстро и без задержек</p>
                    </div>
                    <div className="MainPricesSection">
                        <img src="/4.png" className="MainPricesSectionImage" />
                        <p className="MainPricesSectionInfo">Курьерская доставка: скорость на каждом этапе</p>
                    </div>
                </div>
            </div>
            <div className="MainFaqTitle">Часто задаваемые вопросы (FAQ)</div>
            <div className="MainFaqDiv">
                {
                    faq.map((question) => (
                        <button onClick={() => setSelectedFaqId(selectedFaqId === question.id ? null : question.id)} style={{backgroundColor: selectedFaqId === question.id ? 'black' : 'white'}} className={"MainFaqButton"}>
                            <div className="MainFaqQuestion">
                                <p style={{color: selectedFaqId === question.id ? 'white' : 'black'}}>{question.question}</p>
                                <svg style={{transform: `rotate(${selectedFaqId === question.id ? '-90deg' : '0deg'})`}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 21C20.5523 21 21 20.5523 21 20V11C21 10.4477 20.5523 10 20 10C19.4477 10 19 10.4477 19 11V19H11C10.4477 19 10 19.4477 10 20C10 20.5523 10.4477 21 11 21H20ZM3.29289 4.70711L19.2929 20.7071L20.7071 19.2929L4.70711 3.29289L3.29289 4.70711Z" fill={selectedFaqId === question.id ? 'white' : 'black'}/>
                                </svg>
                            </div>
                            {selectedFaqId === question.id &&
                                <p style={{color: selectedFaqId === question.id ? 'white' : 'black'}} className="MainFaqAnswer">
                                    {question.answer}
                                </p>
                            }
                        </button>
                    ))
                }
            </div>
            <div className="MainTrackDiv">
                <h2 className="MainTrackTitle">Введите трек-номер</h2>
                <p className="MainTrackInfo">Вы сможете видеть его местоположение и статус на каждом этапе перевозки.</p>
                <div className="MainTrackInputDiv">
                    <input 
                        className="MainTrackInput"
                        type="text"
                        placeholder="S01022412345"
                        value={trackNumber}
                        onChange={e => setTrackNumber(e.target.value)}
                    />
                    <button onClick={track_cargo} className="MainTrackButton">
                        Поиск
                    </button>
                </div>
                {
                    (output?.status === "error" || output?.status === "success") &&
                    (
                        <p className="MainTrackOutput">
                            {output.status === "error"
                                ? output.message
                                : <>
                                    Статус для номера отслеживания {trackNumber}: <b>{output.name}</b>
                                </>
                            }
                        </p>
                    )
                }
            </div>
        </div>
    )
}

export default Main
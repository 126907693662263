import React from 'react';

const ReceiptPrintPage = () => {
  const trackNumber = 'S81273897123';
  const receiver = 'Anna Petrova';

  const handlePrint = () => {
    window.print();
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Грузовая квитанция</h1>
      <p><strong>Номер отслеживания:</strong> {trackNumber}</p>
      <p><strong>Получатель:</strong> {receiver}</p>
      <p><strong>Имя Фамилия получателя:</strong> Иванов Сергей</p>
      <p><strong>Телефон:</strong> +7 (999) 123-45-67</p>
      <p><strong>Номер машины:</strong> A123BC777</p>
      <p><strong>Краткая информация о грузе:</strong> Пакет с запчастями для автомобилей.</p>
      <button onClick={handlePrint} style={styles.button}>Печать</button>
    </div>
  );
};

const styles = {
  container: {
    width: '80mm',
    margin: '0 auto',
    padding: '10mm',
    fontSize: '12px',
    lineHeight: '1.5',
    textAlign: 'left',
    border: '1px solid #000',
    borderRadius: '5px',
  },
  title: {
    textAlign: 'center',
    fontSize: '16px',
    marginBottom: '10px',
  },
  button: {
    display: 'block',
    margin: '20px auto',
    padding: '10px 20px',
    fontSize: '14px',
    cursor: 'pointer',
  },
};

export default ReceiptPrintPage;

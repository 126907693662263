import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Faq({ sidebarOpen, setSidebarOpen, user, setUser }){

    const [faq, setFaq] = useState([])
    const [question, setQuestion] = useState('')
    const [answer, setAnswer] = useState('')
    const [selectedEditOptions, setSelectedEditOptions] = useState({
        parameter: null,
        value: null,
        id: null
    })
    const navigate = useNavigate()

    const startEdit = (id, value, parameter) => {

        setSelectedEditOptions({
            parameter: parameter,
            value: value,
            id: id
        })

    }

    const submitEdit = (id, parameter, new_value, old_value) => {
        if(old_value != new_value){
            const username = localStorage.getItem('username');
            const hash = localStorage.getItem('hash');
    
            axios.get(`https://loraandrey.kz/api/change_faq.php`, {
                params: {
                    username: username,
                    hash: hash,
                    id: id,
                    value: new_value,
                    parameter: parameter
                }
            })
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    if(data.faq){
                        setFaq(data.faq)
                    }
                } else {
                    alert(data.message)
                }
            })
            .catch(error => {
                alert(error.toString())
            });
        }

        setSelectedEditOptions({
            parameter: null,
            value: null,
            track_number: null
        })
    
    };

    const add_faq = () => {
        if(question.length * answer.length > 0){
            const username = localStorage.getItem('username');
            const hash = localStorage.getItem('hash');

            axios.get(`https://loraandrey.kz/api/add_faq.php`, {
                params: {
                    username: username,
                    hash: hash,
                    question: question,
                    answer: answer
                }
            })
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    if(data.faq){
                        setFaq(data.faq)
                    }
                } else {
                    alert(data.message)
                }
            })
            .catch(error => {
                alert(error.toString())
            }).finally(() => {
                setAnswer('')
                setQuestion('')
            });
        }
    }

    const delete_faq = (id) => {
        const confirmDelete = window.confirm(`Вы уверены, что хотите удалить Вопрос с идентификатором: ${id}?`);

        if(confirmDelete){
            const username = localStorage.getItem('username');
            const hash = localStorage.getItem('hash');

            axios.get(`https://loraandrey.kz/api/delete_faq.php`, {
                params: {
                    username: username,
                    hash: hash,
                    id: id
                }
            })
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    if(data.faq){
                        setFaq(data.faq)
                    }
                } else {
                    alert(data.message)
                }
            })
            .catch(error => {
                alert(error.toString())
            });
        }
    }

    useEffect(() => {
        const username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');
        // get user
        if(username && hash){
            axios.get('https://loraandrey.kz/api/me.php', {
                params: {
                    username: username,
                    hash: hash,
                }
            }).then(response => {
                const data = response.data;

                if (data.status === 'success') {
                    setUser(data.user)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

            axios.get('https://loraandrey.kz/api/faq.php', {
            }).then(response => {
                const data = response.data;

                if (data.status === 'success') {
                    setFaq(data.faq)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });
        }else {
            navigate('/auth')
        }
    }, [])

    return(
        <div className={sidebarOpen ? "CargoContainer" : "CargoContainer fullwidth"}>
            <div className="Header">
                <div className="HeaderTitleAndButtons">
                    <div className="HeaderLeft">
                        {!sidebarOpen && 
                            <button onClick={() => setSidebarOpen(!sidebarOpen)} className="SidebarButton">
                                <svg style={{marginLeft: '-8px'}} width="24px" height="24px" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 7C4 6.44771 4.44772 6 5 6H24C24.5523 6 25 6.44771 25 7C25 7.55229 24.5523 8 24 8H5C4.44772 8 4 7.55229 4 7Z" fill="#000000"/><path d="M4 13.9998C4 13.4475 4.44772 12.9997 5 12.9997L16 13C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15L5 14.9998C4.44772 14.9998 4 14.552 4 13.9998Z" fill="#000000"/><path d="M5 19.9998C4.44772 19.9998 4 20.4475 4 20.9998C4 21.552 4.44772 21.9997 5 21.9997H22C22.5523 21.9997 23 21.552 23 20.9998C23 20.4475 22.5523 19.9998 22 19.9998H5Z" fill="#000000"/>
                                </svg>
                            </button>
                        }
                        <h2 className="HeaderTitle">Вопросы</h2>
                    </div>
                </div>
            </div>
            <div className="Settings">
                <div className="Setting">
                    <div className='SettingTable'>
                        <table style={{width: '100%', borderCollapse: 'collapse', marginTop: '10px'}}>
                            <thead>
                                <tr className="TableRow">
                                    <th style={{width: 24}} className="TableHeader">&nbsp;</th>
                                    <th className="TableHeader">id</th>
                                    <th className="TableHeader">Вопрос</th>
                                    <th className="TableHeader">Ответ</th>
                                    <th className="TableHeader">Создано</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="TableRow">
                                    <td style={{maxWidth: '100%'}} className="TableDiv"></td>
                                    <td className="TableDiv"></td>
                                    <td className="TableDiv">
                                        <p style={{display: 'flex'}}>
                                            <input value={question} onChange={e => setQuestion(e.target.value)} type="text" className="ParameterInput" placeholder='Новый вопрос'/>
                                        </p>
                                    </td>
                                    <td className="TableDiv">
                                        <p style={{display: 'flex'}}>
                                            <input value={answer} onChange={e => setAnswer(e.target.value)} type="text" className="ParameterInput" placeholder='Ответ на новый вопрос'/>
                                        </p>
                                    </td>
                                    <td className="TableDiv">
                                        <buttom onClick={add_faq} style={{backgroundColor: 'transparent', color: 'black', opacity: question.length * answer.length > 0 ? 1 : 0.5, cursor: 'pointer'}}>
                                            <p>Создать</p>
                                        </buttom>
                                    </td>
                                </tr>
                                {
                                    faq.map((question, index) => (
                                        <tr className="TableRow">
                                            <td style={{maxWidth: '100%'}} className="TableDiv">
                                                <button onClick={() => delete_faq(question.id)} style={{backgroundColor: 'white', border: 'none', cursor: 'pointer'}}>
                                                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 12V17" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M14 12V17" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M4 7H20" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </td>
                                            <td className="TableDiv">{question.id}</td>
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    <input onBlur={() => submitEdit(question.id, 'question', selectedEditOptions.value, question.question)} onFocus={() => startEdit(question.id, question.question, 'question')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter === 'question' ? Math.max(selectedEditOptions.value?.length, 20) : 20} onChange={(e) => startEdit(question.id, e.target.value, 'question')} value={selectedEditOptions.id === question.id && selectedEditOptions.parameter == 'question' ? selectedEditOptions.value : question.question}/>
                                                </p>
                                            </td>
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    <input onBlur={() => submitEdit(question.id, 'answer', selectedEditOptions.value, question.answer)} onFocus={() => startEdit(question.id, question.answer, 'answer')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter === 'answer' ? Math.max(selectedEditOptions.value?.length, 20) : 20} onChange={(e) => startEdit(question.id, e.target.value, 'answer')} value={selectedEditOptions.id === question.id && selectedEditOptions.parameter == 'answer' ? selectedEditOptions.value : question.answer}/>
                                                </p>
                                            </td>
                                            <td className="TableDiv">{question.created_at}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq
import { useState, useEffect } from "react";
import axios from "axios";
import './dashboard.css'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function Dashboard({ sidebarOpen, setSidebarOpen, user, setUser }){

    const data = [
        { date: '01.01.2024', value: 10 },
        { date: '02.01.2024', value: 5 },
        { date: '03.01.2024', value: 16 },
        { date: '04.01.2024', value: 10 },
        { date: '05.01.2024', value: 5 },
        { date: '06.01.2024', value: 0 },
        { date: '07.01.2024', value: 0 },
        { date: '08.01.2024', value: 0 },
        { date: '09.01.2024', value: 0 },
        { date: '10.01.2024', value: 3 },
        { date: '11.01.2024', value: 4 },
        { date: '12.01.2024', value: 6 },
    ];

    return(
        <div className={sidebarOpen ? "CargoContainer" : "CargoContainer fullwidth"}>
            <div className="Header">
                <div className="HeaderTitleAndButtons">
                    <div className="HeaderLeft">
                        {!sidebarOpen && 
                            <button onClick={() => setSidebarOpen(!sidebarOpen)} className="SidebarButton">
                                <svg style={{marginLeft: '-8px'}} width="24px" height="24px" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 7C4 6.44771 4.44772 6 5 6H24C24.5523 6 25 6.44771 25 7C25 7.55229 24.5523 8 24 8H5C4.44772 8 4 7.55229 4 7Z" fill="#000000"/><path d="M4 13.9998C4 13.4475 4.44772 12.9997 5 12.9997L16 13C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15L5 14.9998C4.44772 14.9998 4 14.552 4 13.9998Z" fill="#000000"/><path d="M5 19.9998C4.44772 19.9998 4 20.4475 4 20.9998C4 21.552 4.44772 21.9997 5 21.9997H22C22.5523 21.9997 23 21.552 23 20.9998C23 20.4475 22.5523 19.9998 22 19.9998H5Z" fill="#000000"/>
                                </svg>
                            </button>
                        }
                        <h2 className="HeaderTitle">Дашборд</h2>
                    </div>
                </div>
            </div>
            <div className="DashboardDiv">
                <div className="CargoDashboard">
                    <div className="DashboardHeader">
                        <p style={{color: '#3240a8'}} className="DashboardTitle">Новые Грузы</p>
                        <p style={{color: '#3240a8'}} className="DashboardValue">34</p>
                    </div>
                    <div className="CargoDashboardBody">
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="value" stroke="#3240a8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="CargoDashboard">
                    <div className="DashboardHeader">
                        <p style={{color: '#1d7821'}} className="DashboardTitle">Выданные Грузы</p>
                        <p style={{color: '#1d7821'}} className="DashboardValue">34</p>
                    </div>
                    <div className="CargoDashboardBody">
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="value" stroke="#1d7821" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="CargoDashboard">
                    <div className="DashboardHeader">
                        <p style={{color: '#ad0000'}} className="DashboardTitle">Проблемные Грузы</p>
                        <p style={{color: '#ad0000'}} className="DashboardValue">34</p>
                    </div>
                    <div className="CargoDashboardBody">
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="value" stroke="#ad0000" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="Employees">
                <div className="DashboardHeader">
                        <p className="DashboardTitle">Новые Грузы</p>
                        <p className="DashboardValue">34</p>
                    </div>
                    <div className='EmployeesTable'>
                        <table style={{width: '100%', borderCollapse: 'collapse', marginTop: '10px'}}>
                            <thead>
                                <tr className="TableRow">
                                    <th className="TableHeader">id</th>
                                    <th className="TableHeader">ФИО</th>
                                    <th className="TableHeader">Роль</th>
                                    <th className="TableHeader">Наличные</th>
                                    <th className="TableHeader">Карта</th>
                                    <th className="TableHeader">Перевод</th>
                                    <th className="TableHeader">Каспи</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="TableRow">
                                    <td className="TableDiv">aaaaaaaa</td>
                                    <td className="TableDiv">aaaaaaaaaaaa</td>
                                    <td className="TableDiv">aaaa</td>
                                    <td className="TableDiv">aaaaa</td>
                                    <td className="TableDiv">aaaa</td>
                                    <td className="TableDiv">aa</td>
                                    <td className="TableDiv">aa</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
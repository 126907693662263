import './Settings.css'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

function Settings({ sidebarOpen, setSidebarOpen, user, setUser }){

    const navigate = useNavigate()

    const [storages, setStorages] = useState([])
    const [comments, setComments] = useState([])
    const [statuses, setStatuses] = useState([])

    const [newComment, setNewComment] = useState('')
    const [newStorageSlug, setNewStorageSlug] = useState('')
    const [newStorageTitle, setNewStorageTitle] = useState('')
    const [newStatus, setNewStatus] = useState('')

    const [selectedEditOptions, setSelectedEditOptions] = useState({
        parameter: null,
        value: null,
        id: null
    })

    useEffect(() => {
        const username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');

        if(username && hash){

            // get user
            axios.get('https://loraandrey.kz/api/me.php', {
                params: {
                    username: username,
                    hash: hash,
                }
            }).then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setUser(data.user)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

            // get comments
            axios.get('https://loraandrey.kz/api/get_comments.php')
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setComments(data.comments)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

            // get storages
            axios.get('https://loraandrey.kz/api/get_storages.php')
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setStorages(data.storages)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

            // get statuses
            axios.get('https://loraandrey.kz/api/get_status.php')
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setStatuses(data.statuses)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

        }else {
            navigate('/auth')
        }
    }, [])

    const startEdit = (id, value, parameter) => {

        setSelectedEditOptions({
            parameter: parameter,
            value: value,
            id: id
        })

    }

    const submitEdit = (id, parameter, new_value, old_value) => {
        if(old_value != new_value){
            const username = localStorage.getItem('username');
            const hash = localStorage.getItem('hash');
    
            axios.get(`https://loraandrey.kz/api/change_${parameter}.php`, {
                params: {
                    username: username,
                    hash: hash,
                    id: id,
                    new_value: new_value,
                }
            })
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    if(data.statuses){
                        setStatuses(data.statuses)
                    }else if(data.comments){
                        setComments(data.comments)
                    }else if(data.storages){
                        setStorages(data.storages)
                    }
                } else {
                    alert(data.message)
                }
            })
            .catch(error => {
                alert(error.toString())
            });
        }

        setSelectedEditOptions({
            parameter: null,
            value: null,
            track_number: null
        })
    
    };

    const addStatus = () => {
        if(newStatus.length > 0){
            const username = localStorage.getItem('username');
            const hash = localStorage.getItem('hash');

            axios.get('https://loraandrey.kz/api/add_status.php', {
                params: {
                    username: username,
                    hash: hash,
                    name: newStatus
                }
            })
            .then(response => {
                const data = response.data;

                if (data.status === 'success') {
                    setStatuses(data.statuses)
                } else {
                    alert(data.message)
                }
            })
            .catch(error => {
                alert(error.toString())
            });
            setNewStatus('')
        }
    }

    const addComment = () => {
        if(newComment.length > 0){
            const username = localStorage.getItem('username');
            const hash = localStorage.getItem('hash');

            axios.get('https://loraandrey.kz/api/add_comment.php', {
                params: {
                    username: username,
                    hash: hash,
                    name: newComment
                }
            })
            .then(response => {
                const data = response.data;

                if (data.status === 'success') {
                    setComments(data.comments)
                } else {
                    alert(data.message)
                }
            })
            .catch(error => {
                alert(error.toString())
            });
            setNewComment('')
        }
    }

    const addStorage = () => {
        if(newStorageSlug.length * newStorageTitle.length > 0){
            const username = localStorage.getItem('username');
            const hash = localStorage.getItem('hash');

            axios.get('https://loraandrey.kz/api/add_storage.php', {
                params: {
                    username: username,
                    hash: hash,
                    slug: newStorageSlug,
                    title: newStorageTitle
                }
            })
            .then(response => {
                const data = response.data;

                if (data.status === 'success') {
                    setStorages(data.storages)
                } else {
                    alert(data.message)
                }
            })
            .catch(error => {
                alert(error.toString())
            });
            setNewStorageSlug('')
            setNewStorageTitle('')
        }
    }

    const deleteStatus = (id) => {
        const username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');

        const confirmDelete = window.confirm(`Вы уверены, что хотите удалить статус с идентификатором: ${id}?`);
        
        if (confirmDelete) {
            axios.get('https://loraandrey.kz/api/delete_status.php', {
                params: {
                    username: username,
                    hash: hash,
                    id: id
                }
            })
            .then(response => {
                const data = response.data;

                if (data.status === 'success') {
                    setStatuses(data.statuses);
                } else {
                    alert(data.message);
                }
            })
            .catch(error => {
                alert(error.toString());
            });
        }
    }

    const deleteStorage = (id) => {
        const username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');

        const confirmDelete = window.confirm(`Вы уверены, что хотите удалить склад с идентификатором: ${id}?`);
        
        if (confirmDelete) {
            axios.get('https://loraandrey.kz/api/delete_storage.php', {
                params: {
                    username: username,
                    hash: hash,
                    id: id
                }
            })
            .then(response => {
                const data = response.data;

                if (data.status === 'success') {
                    setStorages(data.storages);
                } else {
                    alert(data.message);
                }
            })
            .catch(error => {
                alert(error.toString());
            });
        }
    }

    const deleteComment = (id) => {
        const username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');

        const confirmDelete = window.confirm(`Вы уверены, что хотите удалить комментарий с идентификатором: ${id}?`);
        
        if (confirmDelete) {
            axios.get('https://loraandrey.kz/api/delete_comment.php', {
                params: {
                    username: username,
                    hash: hash,
                    id: id
                }
            })
            .then(response => {
                const data = response.data;

                if (data.status === 'success') {
                    setComments(data.comments);
                } else {
                    alert(data.message);
                }
            })
            .catch(error => {
                alert(error.toString());
            });
        }
    }
    

    return(
        <div className={sidebarOpen ? "CargoContainer" : "CargoContainer fullwidth"}>
            <div className="Header">
                <div className="HeaderTitleAndButtons">
                    <div className="HeaderLeft">
                        {!sidebarOpen && 
                            <button onClick={() => setSidebarOpen(!sidebarOpen)} className="SidebarButton">
                                <svg style={{marginLeft: '-8px'}} width="24px" height="24px" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 7C4 6.44771 4.44772 6 5 6H24C24.5523 6 25 6.44771 25 7C25 7.55229 24.5523 8 24 8H5C4.44772 8 4 7.55229 4 7Z" fill="#000000"/><path d="M4 13.9998C4 13.4475 4.44772 12.9997 5 12.9997L16 13C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15L5 14.9998C4.44772 14.9998 4 14.552 4 13.9998Z" fill="#000000"/><path d="M5 19.9998C4.44772 19.9998 4 20.4475 4 20.9998C4 21.552 4.44772 21.9997 5 21.9997H22C22.5523 21.9997 23 21.552 23 20.9998C23 20.4475 22.5523 19.9998 22 19.9998H5Z" fill="#000000"/>
                                </svg>
                            </button>
                        }
                        <h2 className="HeaderTitle">Настройки</h2>
                    </div>
                </div>
            </div>
            <div className="Settings">
                <div className="Setting">
                    <div className='SettingHeader'>
                        <h3>Быстрые комментарий</h3>
                    </div>
                    <div className='SettingTable'>
                        <table style={{width: '100%', borderCollapse: 'collapse', marginTop: '10px'}}>
                            <thead>
                                <tr className="TableRow">
                                    <th style={{width: 24}} className="TableHeader">&nbsp;</th>
                                    <th className="TableHeader">id</th>
                                    <th className="TableHeader">Комментарий</th>
                                    <th className="TableHeader">Создано</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr className="TableRow">
                                        <td style={{maxWidth: '100%'}} className="TableDiv"></td>
                                        <td className="TableDiv"></td>
                                        <td className="TableDiv">
                                            <p style={{display: 'flex'}}>
                                                <input value={newComment} onChange={e => setNewComment(e.target.value)} type="text" className="ParameterInput" placeholder='Новое комментарие'/>
                                            </p>
                                        </td>
                                        <td className="TableDiv">
                                            <buttom onClick={addComment} style={{backgroundColor: 'transparent', color: 'black', opacity: newComment.length > 0 ? 1 : 0.5, cursor: 'pointer'}}>
                                                <p>Создать</p>
                                            </buttom>
                                        </td>
                                    </tr>
                                    {
                                    comments.map((comment, index) => (
                                        <tr className="TableRow">
                                            <td style={{maxWidth: '100%'}} className="TableDiv">
                                                <button onClick={() => deleteComment(comment.id)} style={{backgroundColor: 'white', border: 'none', cursor: 'pointer'}}>
                                                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 12V17" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M14 12V17" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M4 7H20" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </td>
                                            <td className="TableDiv">{comment.id}</td>
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    <input onBlur={() => submitEdit(comment.id, 'comment', selectedEditOptions.value, comment.name)} onFocus={() => startEdit(comment.id, comment.name, 'comment')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter == 'comment' ? Math.max(selectedEditOptions.value?.length, 20) : 20} onChange={(e) => startEdit(comment.id, e.target.value, 'comment')} value={selectedEditOptions.id === comment.id && selectedEditOptions.parameter == 'comment' ? selectedEditOptions.value : comment.name}/>
                                                </p>
                                            </td>
                                            <td className="TableDiv">{comment.created_at}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="Setting">
                    <div className='SettingHeader'>
                        <h3>Склады</h3>
                    </div>
                    <div className='SettingTable'>
                        <table style={{width: '100%', borderCollapse: 'collapse', marginTop: '10px'}}>
                            <thead>
                                <tr className="TableRow">
                                    <th style={{width: 24}} className="TableHeader">&nbsp;</th>
                                    <th className="TableHeader">id</th>
                                    <th className="TableHeader">Идентификационный символ</th>
                                    <th className="TableHeader">Название</th>
                                    <th className="TableHeader">Создано</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="TableRow">
                                    <td style={{maxWidth: '100%'}} className="TableDiv"></td>
                                    <td className="TableDiv"></td>
                                    <td className="TableDiv">
                                        <p style={{display: 'flex'}}>
                                            <input value={newStorageSlug} onChange={e => setNewStorageSlug(e.target.value)} type="text" className="ParameterInput" placeholder='Символ нового склада'/>
                                        </p>
                                    </td>
                                    <td className="TableDiv">
                                        <p style={{display: 'flex'}}>
                                            <input value={newStorageTitle} onChange={e => setNewStorageTitle(e.target.value)} type="text" className="ParameterInput" placeholder='Название нового склада'/>
                                        </p>
                                    </td>
                                    <td className="TableDiv">
                                        <buttom onClick={addStorage} style={{backgroundColor: 'transparent', color: 'black', opacity: newStorageSlug.length * newStorageTitle.length > 0 ? 1 : 0.5, cursor: 'pointer'}}>
                                            <p>Создать</p>
                                        </buttom>
                                    </td>
                                </tr>
                                {
                                    storages.map((storage, index) => (
                                        <tr className="TableRow">
                                            <td style={{maxWidth: '100%'}} className="TableDiv">
                                                <button onClick={() => deleteStorage(storage.id)} style={{backgroundColor: 'white', border: 'none', cursor: 'pointer'}}>
                                                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 12V17" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M14 12V17" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M4 7H20" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </td>
                                            <td className="TableDiv">{storage.id}</td>
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    <input onBlur={() => submitEdit(storage.id, 'storage_slug', selectedEditOptions.value, storage.slug)} onFocus={() => startEdit(storage.id, storage.slug, 'storage_slug')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter == 'storage_slug' ? Math.max(selectedEditOptions.value?.length, 20) : 20} onChange={(e) => startEdit(storage.id, e.target.value, 'storage_slug')} value={selectedEditOptions.id === storage.id && selectedEditOptions.parameter == 'storage_slug' ? selectedEditOptions.value : storage.slug}/>
                                                </p>
                                            </td>
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    <input onBlur={() => submitEdit(storage.id, 'storage_title', selectedEditOptions.value, storage.title)} onFocus={() => startEdit(storage.id, storage.title, 'storage_title')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter == 'storage_title' ? Math.max(selectedEditOptions.value?.length, 20) : 20} onChange={(e) => startEdit(storage.id, e.target.value, 'storage_title')} value={selectedEditOptions.id === storage.id && selectedEditOptions.parameter == 'storage_title' ? selectedEditOptions.value : storage.title}/>
                                                </p>
                                            </td>
                                            <td className="TableDiv">{storage.created_at}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="Setting">
                    <div className='SettingHeader'>
                        <h3>Статусы</h3>
                    </div>
                    <div className='SettingTable'>
                        <table style={{width: '100%', borderCollapse: 'collapse', marginTop: '10px', padding: 0}}>
                            <thead>
                                <tr className="TableRow">
                                    <th style={{width: 24}} className="TableHeader">&nbsp;</th>
                                    <th className="TableHeader">id</th>
                                    <th className="TableHeader">Название</th>
                                    <th className="TableHeader">Создано</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="TableRow">
                                    <td style={{maxWidth: '100%'}} className="TableDiv"></td>
                                    <td className="TableDiv"></td>
                                    <td className="TableDiv">
                                        <p style={{display: 'flex'}}>
                                            <input value={newStatus} onChange={e => setNewStatus(e.target.value)} type="text" className="ParameterInput" placeholder='Новый статус'/>
                                        </p>
                                    </td>
                                    <td className="TableDiv">
                                        <buttom onClick={addStatus} style={{backgroundColor: 'transparent', color: 'black', opacity: newStatus.length > 0 ? 1 : 0.5, cursor: 'pointer'}}>
                                            <p>Создать</p>
                                        </buttom>
                                    </td>
                                </tr>
                                {
                                    statuses.map((status, index) => (
                                        <tr className="TableRow">
                                            <td style={{maxWidth: '100%'}} className="TableDiv">
                                                <button onClick={() => deleteStatus(status.id)} style={{backgroundColor: 'white', border: 'none', cursor: 'pointer'}}>
                                                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 12V17" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M14 12V17" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M4 7H20" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </td>
                                            <td className="TableDiv">{status.id}</td>
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    <input onBlur={() => submitEdit(status.id, 'status', selectedEditOptions.value, status.name)} onFocus={() => startEdit(status.id, status.name, 'status')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter == 'status' ? Math.max(selectedEditOptions.value?.length, 20) : 20} onChange={(e) => startEdit(status.id, e.target.value, 'status')} value={selectedEditOptions.id === status.id && selectedEditOptions.parameter == 'status' ? selectedEditOptions.value : status.name}/>
                                                </p>
                                            </td>
                                            <td className="TableDiv">{status.created_at}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Auth from './Pages/Auth/Auth';
import Main from './Pages/Main/Main';
import Sidebar from './Pages/Admin/Sidebar/Sidebar';
import Cargo from './Pages/Admin/Cargo/Cargo';
import AddCargo from './Pages/Admin/AddCargo/AddCargo';
import Settings from './Pages/Admin/Settings/Settings';
import Employees from './Pages/Admin/Employees/Employees';
import AddEmployee from './Pages/Admin/AddEmployee/AddEmployee';
import EditEmployee from './Pages/Admin/EditEmployee/EditEmployee';
import Faq from './Pages/Admin/faq/faq';
import Dashboard from './Pages/Admin/dashboard/dashboard';
import ReceiptPrintPage from './Pages/Admin/ReceiptPrintPage/ReceiptPrintPage';

function App() {

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [user, setUser] = useState(null)

  return (
      <Router>
        <Sidebar sidebarOpen={sidebarOpen} user={user} setSidebarOpen={setSidebarOpen} />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/auth" element={<Auth setUser={setUser} />} />

          <Route path="/admin/cargo" element={<Cargo user={user} setUser={setUser} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />} />
          <Route path="/admin/cargo/add" element={<AddCargo user={user} setUser={setUser} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />} />

          <Route path="/admin/settings" element={<Settings user={user} setUser={setUser} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />} />

          <Route path="/admin/employees" element={<Employees user={user} setUser={setUser} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />} />
          <Route path="/admin/employees/add" element={<AddEmployee user={user} setUser={setUser} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />} />
          <Route path="/admin/employees/edit/:id" element={<EditEmployee user={user} setUser={setUser} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />} />

          <Route path="/admin/faq" element={<Faq user={user} setUser={setUser} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />} />

          <Route path="/admin/dashboard" element={<Dashboard user={user} setUser={setUser} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />} />
          <Route path="/admin/receipt" element={<ReceiptPrintPage user={user} setUser={setUser} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />} />

        </Routes>
      </Router>
  );
}

export default App;

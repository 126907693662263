import { useState, useEffect } from "react";
import "./EditEmployee.css"
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function EditEmployee({ sidebarOpen, setSidebarOpen, user, setUser }){

    const navigate = useNavigate()
    const {id} = useParams()

    const parameters = [
        {parameter: "reciever", name: "Получатель"},
        {parameter: "phone", name: "Телефона"},
        {parameter: "transport", name: "Машина"},
        {parameter: "storage_id", name: "Склад"},
        {parameter: "count", name: "Количество"},
        {parameter: "comment", name: "Комментарий"},
        {parameter: "status_id", name: "Статус"},
        {parameter: "price", name: "Цена"},
        {parameter: "debt", name: "Долг"},
        {parameter: "prepaid", name: "Опл. в Алмате"},
        {parameter: "paid", name: "Опл. в Астане"},
        {parameter: "updated_at", name: "Изменено"},
        {parameter: "updated_by_id", name: "Посл. изменение"},
        {parameter: "created_at", name: "Создано"},
        {parameter: "courier_id", name: "Курьер"},
        {parameter: "image_url", name: "Изображение"},
        {parameter: "history", name: "История"},
    ]

    const [fullName, setFullName] = useState('')
    const [role, setRole] = useState('')
    const [username, setUsername] = useState('')
    const [status, setStatus] = useState('active')

    const [permissions, setPermissions] = useState({
        available_parameters: ["track_number"],
        can_add_cargo: false,
        dashboard: false,
        transactions: false,
        users: false,
        history: false,
        settings: false,
        delete_cargo: false,
        faq: false
    })

    useEffect(() => {
        const current_username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');

        if(current_username && hash){

            // get user
            axios.get('https://loraandrey.kz/api/me.php', {
                params: {
                    username: current_username,
                    hash: hash,
                }
            }).then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setUser(data.user)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

            // get user
            axios.get('https://loraandrey.kz/api/get_employee.php', {
                params: {
                    username: current_username,
                    hash: hash,
                    user_id: id
                }
            }).then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setFullName(data.user.full_name)
                    setRole(data.user.role)
                    setUsername(data.user.username)
                    setPermissions(data.user.permissions)
                    setStatus(data.user.status)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

        }else {
            navigate('/auth')
        }
    }, [])

    const edit_user = () => {
        const current_username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');

        if(current_username && hash){
            if(fullName.length * role.length * username.length * status.length !== 0){
                axios.get('https://loraandrey.kz/api/edit_user.php', {
                    params: {
                        username: current_username,
                        hash: hash,
                        new_full_name: fullName,
                        new_role: role,
                        new_username: username,
                        new_status: status,
                        new_permissions: JSON.stringify(permissions),
                        user_id: id
                    }
                }).then(response => {
                    const data = response.data;
        
                    if (data.status === 'success') {
                        navigate('/admin/employees')
                    } else {
                        navigate('/auth')
                    }
                })
                .catch(error => {
                    navigate('/auth')
                });
            }
        }else {
            navigate('/auth')
        }
    }

    return(
        <div className={sidebarOpen ? "CargoContainer" : "CargoContainer fullwidth"}>
            <div className="Header">
                <div className="HeaderTitleAndButtons">
                    <div className="HeaderLeft">
                        {!sidebarOpen && 
                            <button onClick={() => setSidebarOpen(!sidebarOpen)} className="SidebarButton">
                                <svg style={{marginLeft: '-8px'}} width="24px" height="24px" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 7C4 6.44771 4.44772 6 5 6H24C24.5523 6 25 6.44771 25 7C25 7.55229 24.5523 8 24 8H5C4.44772 8 4 7.55229 4 7Z" fill="#000000"/><path d="M4 13.9998C4 13.4475 4.44772 12.9997 5 12.9997L16 13C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15L5 14.9998C4.44772 14.9998 4 14.552 4 13.9998Z" fill="#000000"/><path d="M5 19.9998C4.44772 19.9998 4 20.4475 4 20.9998C4 21.552 4.44772 21.9997 5 21.9997H22C22.5523 21.9997 23 21.552 23 20.9998C23 20.4475 22.5523 19.9998 22 19.9998H5Z" fill="#000000"/>
                                </svg>
                            </button>
                        }
                        <h2 className="HeaderTitle">Cотрудник {id}</h2>
                    </div>
        
                    <div className="Buttons">
                        <button onClick={edit_user} className={(fullName.length * role.length * username.length * status.length !== 0) ? "HeaderButton active" : "HeaderButton inactive"}>
                            <p>Сохранить</p>
                        </button>
                    </div>
                </div>
            </div>

            <div className="AddCargoDiv">
                <h3 className="ParametersTitle">Обшая информация</h3>
                <div className="ParametersDiv">
                    <div className="Parameter">
                        <label className="ParameterLabel">ФИО</label>
                        <input value={fullName} onChange={e => setFullName(e.target.value)} type="text" className="ParameterInput" />
                    </div>
                    <div className="Parameter">
                        <label className="ParameterLabel">Роль</label>
                        <input value={role} onChange={e => setRole(e.target.value)} type="text" className="ParameterInput" />
                    </div>
                    <div className="Parameter">
                        <label className="ParameterLabel">Логин</label>
                        <input value={username} onChange={e => setUsername(e.target.value)} type="text" className="ParameterInput" />
                    </div>
                    <div className="Parameter">
                        <label className="ParameterLabel">Статус</label>
                        <select type="text" value={status} onChange={e => setStatus(e.target.value)} className="ParameterInput" >
                            <option value="active">Активно</option>
                            <option value="inactive">Не активно</option>
                        </select>
                    </div>
                </div>
                <h3 className="ParametersTitle">Доступ к грузам</h3> 
                <div className="ParametersDiv">
                    <div className="Comment">
                        <input
                            type="checkbox"
                            className="ParameterInput"
                            checked={permissions.available_parameters.includes("track_number")}
                            contentEditable={false}
                        />
                        <label className="ParameterLabel">Номер груза</label>
                    </div>
                    {
                        parameters.map((parameter) => (
                            <div key={parameter.parameter} className="Comment">
                                <input
                                    type="checkbox"
                                    className="ParameterInput"
                                    checked={permissions.available_parameters.includes(parameter.parameter)}
                                    onChange={e => {
                                        const updated_permissions = { ...permissions };
                                        if (e.target.checked) {
                                            updated_permissions.available_parameters.push(parameter.parameter);
                                        } else {
                                            updated_permissions.available_parameters = updated_permissions.available_parameters.filter(
                                                param => param !== parameter.parameter
                                            );
                                        }
                                        setPermissions(updated_permissions);
                                    }}
                                />
                                <label className="ParameterLabel">{parameter.name}</label>
                            </div>
                        ))
                    }
                </div>
                <h3 className="ParametersTitle">Доступы</h3>
                <div className="ParametersDiv">
                    <div className="Comment">
                        <input
                            type="checkbox"
                            className="ParameterInput"
                            checked={permissions.can_add_cargo}
                            onChange={e => {
                                const updated_permissions = { ...permissions };
                                updated_permissions.can_add_cargo = e.target.checked;
                                setPermissions(updated_permissions);
                            }}
                        />
                        <label className="ParameterLabel">Добавить груз</label>
                    </div>
                    <div className="Comment">
                        <input
                            type="checkbox"
                            className="ParameterInput"
                            checked={permissions.delete_cargo}
                            onChange={e => {
                                const updated_permissions = { ...permissions };
                                updated_permissions.delete_cargo = e.target.checked;
                                setPermissions(updated_permissions);
                            }}
                        />
                        <label className="ParameterLabel">Удалить груз</label>
                    </div>
                    <div className="Comment">
                        <input
                            type="checkbox"
                            className="ParameterInput"
                            checked={permissions.dashboard}
                            onChange={e => {
                                const updated_permissions = { ...permissions };
                                updated_permissions.dashboard = e.target.checked;
                                setPermissions(updated_permissions);
                            }}
                        />
                        <label className="ParameterLabel">Дашборд</label>
                    </div>
                    <div className="Comment">
                        <input
                            type="checkbox"
                            className="ParameterInput"
                            checked={permissions.transactions}
                            onChange={e => {
                                const updated_permissions = { ...permissions };
                                updated_permissions.transactions = e.target.checked;
                                setPermissions(updated_permissions);
                            }}
                        />
                        <label className="ParameterLabel">Переводы</label>
                    </div>
                    <div className="Comment">
                        <input
                            type="checkbox"
                            className="ParameterInput"
                            checked={permissions.users}
                            onChange={e => {
                                const updated_permissions = { ...permissions };
                                updated_permissions.users = e.target.checked;
                                setPermissions(updated_permissions);
                            }}
                        />
                        <label className="ParameterLabel">Сотрудники</label>
                    </div>
                    <div className="Comment">
                        <input
                            type="checkbox"
                            className="ParameterInput"
                            checked={permissions.history}
                            onChange={e => {
                                const updated_permissions = { ...permissions };
                                updated_permissions.history = e.target.checked;
                                setPermissions(updated_permissions);
                            }}
                        />
                        <label className="ParameterLabel">История дейсвий</label>
                    </div>
                    <div className="Comment">
                        <input
                            type="checkbox"
                            className="ParameterInput"
                            checked={permissions.faq}
                            onChange={e => {
                                const updated_permissions = { ...permissions };
                                updated_permissions.faq = e.target.checked;
                                setPermissions(updated_permissions);
                            }}
                        />
                        <label className="ParameterLabel">FAQ</label>
                    </div>
                    <div className="Comment">
                        <input
                            type="checkbox"
                            className="ParameterInput"
                            checked={permissions.settings}
                            onChange={e => {
                                const updated_permissions = { ...permissions };
                                updated_permissions.settings = e.target.checked;
                                setPermissions(updated_permissions);
                            }}
                        />
                        <label className="ParameterLabel">Настройки</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditEmployee
import { useState, useEffect, Fragment } from "react";
import "./Cargo.css"
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Cargo({ sidebarOpen, setSidebarOpen, user, setUser }){

    const navigate = useNavigate()

    const [selectedEditOptions, setSelectedEditOptions] = useState({
        parameter: null,
        value: null,
        track_number: null
    })

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const today = formatDate(new Date());

    const [submitOpen, setSubmitOpen] = useState(false)
    const [submitPaid, setSubmitPaid] = useState({
        cash: 0,
        bank_card: 0,
        transfer: 0,
        kaspi: 0
    })

    const [keywords, setKeywords] = useState('');
    const [dateFrom, setDateFrom] = useState(today);
    const [dateTo, setDateTo] = useState(today);
    const [selectedStorage, setSelectedStorage] = useState('all')
    const [selectedCourier, setSelectedCourier] = useState('all')
    const [selectedStatus, setSelectedStatus] = useState('all')

    const [selectedTrackNumberHistory, setSelectedTrackNumberHistory] = useState('')

    const [selectedTrackNumbers, setSelectedTrackNumbers] = useState([])

    const [cargo, setCargo] = useState([])
    const [statuses, setStatuses] = useState([])
    const [couriers, setCouriers] = useState([])
    const [storages, setStorages] = useState([])

    useEffect(() => {
        const username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');

        if(username && hash){
            axios.get('https://loraandrey.kz/api/get_status.php')
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setStatuses(data.statuses)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

            // get couriers
            axios.get('https://loraandrey.kz/api/get_couriers.php')
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setCouriers(data.couriers)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

            // get storages
            axios.get('https://loraandrey.kz/api/get_storages.php')
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setStorages(data.storages)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

            getCargo()
        }else {
            navigate('/auth')
        }
    }, [])

    const getCargo = () => {
        const username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');
        setSelectedTrackNumbers([])

        if(username && hash){
            axios.get('https://loraandrey.kz/api/get_cargo.php', {
                params: {
                    username: username,
                    hash: hash,
                    key_words: keywords,
                    date_from: dateFrom,
                    date_to: dateTo,
                    storage_id: selectedStorage,
                    status_id: selectedStatus,
                    courier_id: selectedCourier
                }
            })
            .then(response => {
                const data = response.data;
                console.log(data)
                if (data.status === 'success') {
                    setUser(data.user)
                    setCargo(data.cargo)
                } else {
                    alert(data.error)
                }
            })
            .catch(error => {
                navigate('/auth')
            });
        }else{
            navigate('/auth')
        }
    }

    const startEdit = (track_number, value, parameter) => {

        setSelectedEditOptions({
            parameter: parameter,
            value: value,
            track_number: track_number
        })

    }

    const submitCargo = async () => {
        const selectedCargos = cargo.filter(item => selectedTrackNumbers.includes(item.track_number));
    
        // Calculate the total price of selected cargos
        const totalPrice = selectedCargos.reduce((sum, item) => sum + item.price + item.debt, 0);
    
        // Total paid amount
        const totalPaid = submitPaid.cash + submitPaid.bank_card + submitPaid.transfer + submitPaid.kaspi;
    
        if (totalPaid !== totalPrice) {
            alert('Оплата не соответствует стоимости!');
            return;
        }
    
        // Initialize remaining payment amounts
        let remainingPayments = {
            cash: submitPaid.cash,
            bank_card: submitPaid.bank_card,
            transfer: submitPaid.transfer,
            kaspi: submitPaid.kaspi,
        };
    
        // Distribute payments sequentially for each cargo
        for (const cargo of selectedCargos) {
            let remainingCargoPrice = cargo.price + cargo.debt;
    
            // Initialize payments for this cargo
            let cashPayment = 0;
            let bankCardPayment = 0;
            let transferPayment = 0;
            let kaspiPayment = 0;
    
            // Use cash first
            if (remainingPayments.cash > 0 && remainingCargoPrice > 0) {
                cashPayment = Math.min(remainingPayments.cash, remainingCargoPrice);
                remainingPayments.cash -= cashPayment;
                remainingCargoPrice -= cashPayment;
            }
    
            // Use bank card next
            if (remainingCargoPrice > 0 && remainingPayments.bank_card > 0) {
                bankCardPayment = Math.min(remainingPayments.bank_card, remainingCargoPrice);
                remainingPayments.bank_card -= bankCardPayment;
                remainingCargoPrice -= bankCardPayment;
            }
    
            // Use transfer next
            if (remainingCargoPrice > 0 && remainingPayments.transfer > 0) {
                transferPayment = Math.min(remainingPayments.transfer, remainingCargoPrice);
                remainingPayments.transfer -= transferPayment;
                remainingCargoPrice -= transferPayment;
            }
    
            // Use kaspi last
            if (remainingCargoPrice > 0 && remainingPayments.kaspi > 0) {
                kaspiPayment = Math.min(remainingPayments.kaspi, remainingCargoPrice);
                remainingPayments.kaspi -= kaspiPayment;
                remainingCargoPrice -= kaspiPayment;
            }
    
            // Submit the calculated payments for this cargo
            await submitEdit(cargo.track_number, 'paid', JSON.stringify({
                cash: cashPayment,
                bank_card: bankCardPayment,
                transfer: transferPayment,
                kaspi: kaspiPayment
            }), JSON.stringify(cargo.paid)).then(async() => {
                await submitEdit(cargo.track_number, 'status_id', 3, cargo.status_id)
            });
        }

        getCargo()
    };
    
    const delete_cargo = () => {
        const username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');
    
        // Confirmation dialog
        const confirmDelete = window.confirm("Вы действительно хотите удалить выбранные грузы?");
    
        if (confirmDelete) {
            // Proceed with the deletion if the user confirms
            axios.get('https://loraandrey.kz/api/delete_cargo.php', {
                params: {
                    username: username,
                    hash: hash,
                    track_numbers: selectedTrackNumbers.join(',') // Send as a comma-separated string
                }
            })
            .then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    getCargo();
                } else {
                    alert(data.message);
                }
            })
            .catch(error => {
                alert(error.toString());
            });
        }
    };

    const submitEdit = (track_number, parameter, new_value, old_value) => {
        return new Promise((resolve, reject) => {
            if (old_value !== new_value) {
                const username = localStorage.getItem('username');
                const hash = localStorage.getItem('hash');
    
                axios.get('https://loraandrey.kz/api/change_cargo_parameter.php', {
                    params: {
                        username: username,
                        hash: hash,
                        track_number: track_number,
                        value: new_value,
                        parameter: parameter
                    }
                })
                .then(response => {
                    const data = response.data;
    
                    if (data.status === 'success') {
                        const updatedCargo = cargo.map(item => {
                            if (item.track_number === track_number) {
                                return data.updated_cargo;
                            }
                            return item;
                        });
                        setCargo(updatedCargo);
                        resolve();
                    } else {
                        alert(data.message);
                    }
                })
                .catch(error => {
                    alert(error.toString());
                    reject(error);
                });
            } else {
                resolve();
            }
    
            setSelectedEditOptions({
                parameter: null,
                value: null,
                track_number: null
            });
        });
    };
    

    return(
        <div className={sidebarOpen ? "CargoContainer" : "CargoContainer fullwidth"}>
            <div className="Header">
                <div className="HeaderTitleAndButtons">
                    <div className="HeaderLeft">
                        {!sidebarOpen && 
                            <button onClick={() => setSidebarOpen(!sidebarOpen)} className="SidebarButton">
                                <svg style={{marginLeft: '-8px'}} width="24px" height="24px" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 7C4 6.44771 4.44772 6 5 6H24C24.5523 6 25 6.44771 25 7C25 7.55229 24.5523 8 24 8H5C4.44772 8 4 7.55229 4 7Z" fill="#000000"/><path d="M4 13.9998C4 13.4475 4.44772 12.9997 5 12.9997L16 13C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15L5 14.9998C4.44772 14.9998 4 14.552 4 13.9998Z" fill="#000000"/><path d="M5 19.9998C4.44772 19.9998 4 20.4475 4 20.9998C4 21.552 4.44772 21.9997 5 21.9997H22C22.5523 21.9997 23 21.552 23 20.9998C23 20.4475 22.5523 19.9998 22 19.9998H5Z" fill="#000000"/>
                                </svg>
                            </button>
                        }
                        <h2 className="HeaderTitle">Грузы</h2>
                    </div>
        
                    <div className="Buttons">
                        <button onClick={() => selectedTrackNumbers.length > 0 ? delete_cargo() : console.log('')} style={{borderColor: 'red', color: 'red'}} className={selectedTrackNumbers.length === 0 ? "HeaderButton inactive" : "HeaderButton active"}>
                            <p>Удалить</p>
                        </button>
                        <button onClick={() => setSubmitOpen(!submitOpen)} className={selectedTrackNumbers.length === 0 ? "HeaderButton inactive" : "HeaderButton active"}>
                            <p>Выдать</p>
                        </button>
                        {
                            (selectedTrackNumbers.length > 0 && submitOpen) &&
                            <div className="SubmitDiv">
                                <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", justifyContent: 'space-between', gap: 30}}>
                                &nbsp;Сумма:&nbsp;<p style={{width: '100%', textAlign: 'right'}}>{cargo.filter(item => selectedTrackNumbers.includes(item.track_number)).reduce((sum, item) => sum + item.price + item.debt, 0)}&nbsp;₸&nbsp;</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", justifyContent: 'space-between', gap: 30}}>
                                &nbsp;Оплачено:&nbsp;<p style={{width: '100%', textAlign: 'right'}}>{submitPaid.cash + submitPaid.bank_card + submitPaid.transfer + submitPaid.kaspi}&nbsp;₸&nbsp;</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", justifyContent: 'space-between', borderTop: '1px solid rgba(0,0,0,0.1)'}}>
                                &nbsp;Нал:&nbsp;<input onChange={e => setSubmitPaid({cash: parseFloat(e.target.value) || 0, bank_card: submitPaid.bank_card, transfer: submitPaid.transfer, kaspi: submitPaid.kaspi})} value={submitPaid.cash} style={{textAlign: 'right', width: '100%'}} type="text" pattern="\d*" placeholder="Наличные" className="ParameterInput"/>&nbsp;
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", justifyContent: 'space-between', marginTop: 1}}>
                                &nbsp;Карта:&nbsp;<input onChange={e => setSubmitPaid({cash: submitPaid.cash, bank_card: parseFloat(e.target.value) || 0, transfer: submitPaid.transfer, kaspi: submitPaid.kaspi})} value={submitPaid.bank_card} style={{textAlign: 'right', width: '100%'}} type="text" pattern="\d*" placeholder="Наличные" className="ParameterInput"/>&nbsp;
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", justifyContent: 'space-between', marginTop: 1}}>
                                &nbsp;Перевод:&nbsp;<input onChange={e => setSubmitPaid({cash: submitPaid.cash, bank_card: submitPaid.bank_card, transfer: parseFloat(e.target.value) || 0, kaspi: submitPaid.kaspi})} value={submitPaid.transfer} style={{textAlign: 'right', width: '100%'}} type="text" pattern="\d*" placeholder="Наличные" className="ParameterInput"/>&nbsp;
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", justifyContent: 'space-between', marginTop: 1}}>
                                &nbsp;Каспи:&nbsp;<input onChange={e => setSubmitPaid({cash: submitPaid.cash, bank_card: submitPaid.bank_card, transfer: submitPaid.transfer, kaspi: parseFloat(e.target.value) || 0})} value={submitPaid.kaspi} style={{textAlign: 'right', width: '100%'}} type="text" pattern="\d*" placeholder="Наличные" className="ParameterInput"/>&nbsp;
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', borderTop: '1px solid rgba(0,0,0,0.1)', gap: '1px', backgroundColor: 'rgba(0,0,0,0.1)', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px'}}>
                                    <button onClick={() => setSubmitOpen(!submitOpen)} style={{width: '50%', padding: '10px 0px', color: 'black', cursor: 'pointer', border: 'none', backgroundColor: 'white', borderBottomLeftRadius: '8px'}}>
                                        Отмена
                                    </button>
                                    <button onClick={submitCargo} style={{width: '50%', padding: '10px 0px', color: 'black', cursor: 'pointer', border: 'none', backgroundColor: 'white', borderBottomRightRadius: '8px', opacity: (submitPaid.cash + submitPaid.bank_card + submitPaid.transfer + submitPaid.kaspi) === cargo.filter(item => selectedTrackNumbers.includes(item.track_number)).reduce((sum, item) => sum + item.price + item.debt, 0) ? 1 : 0.6}}>
                                        Выдать
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="Search">
                    <div className="Filters">
                        <div className="Filter">
                            <p>ключевые слова</p>
                            <input 
                                onChange={e => setKeywords(e.target.value)}
                                value={keywords}
                                placeholder="Получатель, телефон, комментарий, количество мест..."
                                type="text"
                            />
                        </div>
                        <div className="Filter">
                            <p>с</p>
                            <input 
                                onChange={e => setDateFrom(e.target.value)}
                                value={dateFrom}
                                type="date"
                            />
                        </div>
                        <div className="Filter">
                            <p>до</p>
                            <input 
                                onChange={e => setDateTo(e.target.value)}
                                value={dateTo}
                                type="date"
                            />
                        </div>
                        <div className="Filter">
                            <p>склад</p>
                            <select 
                                onChange={e => setSelectedStorage(e.target.value)}
                                value={selectedStorage}
                            >
                                <option value="all">Все</option>
                                {
                                    storages.map((storage, index) => (
                                        <option key={storage.title} value={storage.id}>{storage.title}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="Filter">
                            <p>курьер</p>
                            <select 
                                onChange={e => setSelectedCourier(e.target.value)}
                                value={selectedCourier}
                            >
                                <option value="all">Все</option>
                                {
                                    couriers.map((courier, index) => (
                                        <option key={courier.full_name} value={courier.id}>{courier.full_name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="Filter">
                            <p>статус</p>
                            <select 
                                onChange={e => setSelectedStatus(e.target.value)}
                                value={selectedStatus}
                            >
                                <option value="all">Все</option>
                                {
                                    statuses.map((status, index) => (
                                        <option key={status.name} value={status.id}>{status.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="SearchButton">
                        <p>&nbsp;</p>
                        <button onClick={getCargo}>
                            <p>Поиск</p>
                        </button>
                    </div>
                </div>
            </div>

            <div className="OrdersTable">
                <table>
                    {
                        cargo.length > 0 &&
                        <thead>
                            <tr>
                                <th style={{zIndex: 999}} className="TableHeader sticky">
                                <input
                                    type="checkbox"
                                    checked={
                                        (() => {
                                            const cargoTrackNumbers = cargo.map(item => item.track_number);
                                            return [...cargoTrackNumbers].sort().join(',') === [...selectedTrackNumbers].sort().join(',');
                                        })()
                                    }
                                    onChange={e => {
                                        if(e.target.checked){
                                            const cargoTrackNumbers = cargo.map(item => item.track_number);
                                            
                                            const totals = cargo.reduce((acc, item) => {
                                                acc.cash += item.paid.cash || 0;
                                                acc.bank_card += item.paid.bank_card || 0;
                                                acc.transfer += item.paid.transfer || 0;
                                                acc.kaspi += item.paid.kaspi || 0;
                                                return acc;
                                            }, { cash: 0, bank_card: 0, transfer: 0, kaspi: 0 });
                                            
                                            setSubmitPaid(totals);
                                            setSelectedTrackNumbers(cargoTrackNumbers)
                                        }else {
                                            setSelectedTrackNumbers([])
                                            setSubmitPaid({
                                                cash: 0,
                                                bank_card: 0,
                                                transfer: 0,
                                                kaspi: 0
                                            })
                                        }
                                    }}
                                />
                                </th>
                                <th className="TableHeader">Номер груза</th>
                                {user.permissions.available_parameters.includes('reciever') && <th className="TableHeader">Получатель</th>}
                                {user.permissions.available_parameters.includes('phone') && <th className="TableHeader">Телефон</th>}
                                {user.permissions.available_parameters.includes('transport') && <th className="TableHeader">Машина</th>}
                                {user.permissions.available_parameters.includes('storage_id') &&  <th className="TableHeader">Склад</th>}
                                {user.permissions.available_parameters.includes('count') && <th className="TableHeader">Количество</th>}
                                {user.permissions.available_parameters.includes('status_id') && <th className="TableHeader">Статус</th>}
                                {user.permissions.available_parameters.includes('price') && <th className="TableHeader">Стоимость</th>}
                                {user.permissions.available_parameters.includes('debt') && <th className="TableHeader">Долг</th>}
                                {user.permissions.available_parameters.includes('prepaid') && <th className="TableHeader">Опл. в Алмате</th>}
                                {user.permissions.available_parameters.includes('paid') && <th style={{width: '200px'}} className="TableHeader">Опл. в Астане</th>}
                                {user.permissions.available_parameters.includes('comment') && <th className="TableHeader">Комментарий</th>}
                                {user.permissions.available_parameters.includes('courier_id') && <th className="TableHeader">Курьер</th>}
                                {user.permissions.available_parameters.includes('updated_at') && <th className="TableHeader">Изменено</th>}
                                {user.permissions.available_parameters.includes('updated_by_id') && <th className="TableHeader">Посл. изменение</th>}
                                {user.permissions.available_parameters.includes('created_at') && <th className="TableHeader">Создано</th>}
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {
                            cargo.map((item, index) => (
                                <Fragment>
                                    <tr className="TableRow" key={index} >
                                        <td className="TableDiv sticky">
                                            <input
                                                type="checkbox"
                                                checked={selectedTrackNumbers.includes(item.track_number)}
                                                onChange={() => {
                                                    let updatedSelectedTrackNumbers = [...selectedTrackNumbers];
                                                    
                                                    if (selectedTrackNumbers.includes(item.track_number)) {
                                                        updatedSelectedTrackNumbers = updatedSelectedTrackNumbers.filter(
                                                            (track_number) => track_number !== item.track_number
                                                        );
                                                    } else {
                                                        updatedSelectedTrackNumbers = [...updatedSelectedTrackNumbers, item.track_number];
                                                    }
                                                    setSubmitPaid({
                                                        cash: cargo.filter(item => updatedSelectedTrackNumbers.includes(item.track_number)).reduce((sum, item) => sum + item.paid.cash, 0),
                                                        bank_card: cargo.filter(item => updatedSelectedTrackNumbers.includes(item.track_number)).reduce((sum, item) => sum + item.paid.bank_card, 0),
                                                        transfer: cargo.filter(item => updatedSelectedTrackNumbers.includes(item.track_number)).reduce((sum, item) => sum + item.paid.transfer, 0),
                                                        kaspi: cargo.filter(item => updatedSelectedTrackNumbers.includes(item.track_number)).reduce((sum, item) => sum + item.paid.kaspi, 0),
                                                    })
                                                    setSelectedTrackNumbers(updatedSelectedTrackNumbers);
                                                }}
                                            />
                                            {   (item.history != null && user.permissions.available_parameters.includes('history')) &&
                                                <label style={{ cursor: 'pointer', marginLeft: '6px' }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedTrackNumberHistory === item.track_number}
                                                        onChange={() =>
                                                            selectedTrackNumberHistory === item.track_number
                                                            ? setSelectedTrackNumberHistory('')
                                                            : setSelectedTrackNumberHistory(item.track_number)
                                                        }
                                                        style={{ display: 'none' }}
                                                    />
                                                    <svg style={{ transform: selectedTrackNumberHistory === item.track_number ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }} width="13px" height="13px" viewBox="0 0 440 440" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_237_7)">
                                                            <path d="M10.237 143.69C-2.78066 156.707 -2.78066 177.813 10.237 190.83L173.31 353.743C199.35 379.757 241.543 379.747 267.57 353.723L430.58 190.71C443.6 177.693 443.6 156.587 430.58 143.569C417.563 130.552 396.457 130.552 383.44 143.569L243.92 283.09C230.903 296.11 209.797 296.107 196.78 283.09L57.3773 143.69C44.36 130.672 23.2543 130.672 10.237 143.69Z" fill="#0F0F0F"/>
                                                            </g>
                                                        <defs>
                                                            <clipPath id="clip0_237_7">
                                                                <rect width="440" height="440" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </label>
                                            }
                                        </td>
                                        <td className="TableDiv">{item.track_number}</td>
                                        {user.permissions.available_parameters.includes('reciever') &&
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    <input onBlur={async() => await submitEdit(item.track_number, 'reciever', selectedEditOptions.value, item.reciever)} onFocus={() => startEdit(item.track_number, item.reciever, 'reciever')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter == 'reciever' ? Math.max(selectedEditOptions.value?.length, 20) : 20} onChange={(e) => startEdit(item.track_number, e.target.value, 'reciever')} value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'reciever' ? selectedEditOptions.value : item.reciever}/>
                                                </p>
                                            </td>
                                        }
                                        {user.permissions.available_parameters.includes('phone') &&
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    <input onBlur={async() => await submitEdit(item.track_number, 'phone', selectedEditOptions.value, item.phone)} onFocus={() => startEdit(item.track_number, item.phone, 'phone')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter == 'phone' ? Math.max(selectedEditOptions.value?.length, 10) : 10} onChange={(e) => startEdit(item.track_number, e.target.value, 'phone')} value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'phone' ? selectedEditOptions.value : item.phone}/>
                                                </p>
                                            </td>
                                        }
                                        {user.permissions.available_parameters.includes('transport') && 
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    <input onBlur={async() => await submitEdit(item.track_number, 'transport', selectedEditOptions.value, item.transport)} onFocus={() => startEdit(item.track_number, item.transport, 'transport')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter == 'transport' ? Math.max(selectedEditOptions.value?.length, 5) : 5} onChange={(e) => startEdit(item.track_number, e.target.value, 'transport')} value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'transport' ? selectedEditOptions.value : item.transport}/>
                                                </p>
                                            </td>
                                        }
                                        {user.permissions.available_parameters.includes('storage_id') && 
                                            <td className="TableDiv">
                                                <select onChange={async(e) => await submitEdit(item.track_number, 'storage_id', e.target.value, item.storage_id)} value={item.storage_id} className="ParameterInput">
                                                    {
                                                    storages.map((storage, index) => (
                                                        <option key={storage.title} value={storage.id}>{storage.title}</option>
                                                    ))
                                                    }
                                                </select>
                                            </td>
                                        }
                                        {user.permissions.available_parameters.includes('count') && 
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    <input onBlur={async() => await submitEdit(item.track_number, 'count', selectedEditOptions.value, item.count)} onFocus={() => startEdit(item.track_number, item.count, 'count')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter == 'count' ? Math.max(selectedEditOptions.value?.length, 20) : 20} onChange={(e) => startEdit(item.track_number, e.target.value, 'count')} value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'count' ? selectedEditOptions.value : item.count}/>
                                                </p>
                                            </td>
                                        }
                                        {user.permissions.available_parameters.includes('status_id') && 
                                            <td className="TableDiv">
                                                <select onChange={async(e) => await submitEdit(item.track_number, 'status_id', e.target.value, item.status_id)} value={item.status_id} className="ParameterInput">
                                                {
                                                    statuses.map((status, index) => (
                                                        <option key={status} value={status.id}>{status.name}</option>
                                                    ))
                                                }
                                                </select>
                                            </td>
                                        }
                                        {user.permissions.available_parameters.includes('price') && 
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    ₸&nbsp; 
                                                    <input pattern="\d*" onBlur={async() => await submitEdit(item.track_number, 'price', selectedEditOptions.value, item.price)} onFocus={() => startEdit(item.track_number, item.price, 'price')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter == 'price' ? Math.max(selectedEditOptions.value?.length, 5) : 5} onChange={(e) => startEdit(item.track_number, e.target.value, 'price')} value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'price' ? selectedEditOptions.value : item.price}/>
                                                </p>
                                            </td>
                                        }
                                        {user.permissions.available_parameters.includes('debt') && 
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    ₸&nbsp; 
                                                    <input pattern="\d*" onBlur={async() => await submitEdit(item.track_number, 'debt', selectedEditOptions.value, item.debt)} onFocus={() => startEdit(item.track_number, item.debt, 'debt')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter == 'debt' ? Math.max(selectedEditOptions.value?.length, 5) : 5} onChange={(e) => startEdit(item.track_number, e.target.value, 'debt')} value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'debt' ? selectedEditOptions.value : item.debt}/>
                                                </p>
                                            </td>
                                        }
                                        {(user.permissions.available_parameters.includes('prepaid')) && 
                                            <td className="TableDiv" >
                                                {
                                                    (selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter === 'prepaid') ?
                                                    <div style={{backgroundColor: 'rgba(0,0,0,0.1)', zIndex: 99999, margin: '-10px 0px', top: 0, display: 'flex', flexDirection: 'column', gap: 0}}>
                                                        <div onClick={() => startEdit(item.track_number, item.prepaid, 'prepaid')} style={{display: 'flex', alignItems: 'center', padding: "10px 0px", backgroundColor: 'white', justifyContent: 'space-between', gap: 30}}>
                                                        &nbsp;В Обшем:&nbsp;<p style={{width: '100%', textAlign: 'right'}}>{item.prepaid.cash + item.prepaid.bank_card + item.prepaid.transfer + item.prepaid.kaspi}&nbsp;</p>
                                                        </div>      
                                                        <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", backgroundColor: 'white', justifyContent: 'space-between'}}>
                                                        &nbsp;Нал:&nbsp;<input style={{textAlign: 'right', width: '100%'}} type="text" pattern="\d*" onBlur={async() => await submitEdit(item.track_number, 'prepaid', JSON.stringify(selectedEditOptions.value), JSON.stringify(item.prepaid))} onChange={(e) => startEdit(item.track_number, {cash: parseFloat(e.target.value), bank_card: item.prepaid.bank_card, transfer: item.prepaid.transfer, kaspi: item.prepaid.kaspi}, 'prepaid')} onFocus={() => startEdit(item.track_number, item.prepaid, 'prepaid')} placeholder="Наличные" className="ParameterInput" value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'prepaid' ? selectedEditOptions.value.cash || 0 : item.prepaid.cash || 0}/>&nbsp;
                                                        </div>
                                                        <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", backgroundColor: 'white', justifyContent: 'space-between', marginTop: 1}}>
                                                        &nbsp;Карта:&nbsp;<input style={{textAlign: 'right', width: '100%'}} type="text" pattern="\d*" onBlur={async() => await submitEdit(item.track_number, 'prepaid', JSON.stringify(selectedEditOptions.value), JSON.stringify(item.prepaid))} onChange={(e) => startEdit(item.track_number, {cash: item.prepaid.cash, bank_card: parseFloat(e.target.value), transfer: item.prepaid.transfer, kaspi: item.prepaid.kaspi}, 'prepaid')} onFocus={() => startEdit(item.track_number, item.prepaid, 'prepaid')} placeholder="Банк. карта" className="ParameterInput" value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'prepaid' ? selectedEditOptions.value.bank_card || 0 : item.prepaid.bank_card || 0}/>&nbsp;
                                                        </div>
                                                        <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", backgroundColor: 'white', justifyContent: 'space-between', marginTop: 1}}>
                                                        &nbsp;Перевод:&nbsp;<input style={{textAlign: 'right', width: '100%'}} type="text" pattern="\d*" onBlur={async() => await submitEdit(item.track_number, 'prepaid', JSON.stringify(selectedEditOptions.value), JSON.stringify(item.prepaid))} onChange={(e) => startEdit(item.track_number, {cash: item.prepaid.cash, bank_card: item.prepaid.bank_card, transfer: parseFloat(e.target.value), kaspi: item.prepaid.kaspi}, 'prepaid')} onFocus={() => startEdit(item.track_number, item.prepaid, 'prepaid')} placeholder="Перевод" className="ParameterInput" value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'prepaid' ? selectedEditOptions.value.transfer || 0 : item.prepaid.transfer || 0}/>&nbsp;
                                                        </div>
                                                        <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", backgroundColor: 'white', justifyContent: 'space-between', marginTop: 1}}>
                                                        &nbsp;Карта&nbsp;:<input style={{textAlign: 'right', width: '100%'}} type="text" pattern="\d*" onBlur={async() => await submitEdit(item.track_number, 'prepaid', JSON.stringify(selectedEditOptions.value), JSON.stringify(item.prepaid))} onChange={(e) => startEdit(item.track_number, {cash: item.prepaid.cash, bank_card: item.prepaid.bank_card, transfer: item.prepaid.transfer, kaspi: parseFloat(e.target.value)}, 'prepaid')} onFocus={() => startEdit(item.track_number, item.prepaid, 'prepaid')} placeholder="Каспи" className="ParameterInput" value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'prepaid' ? selectedEditOptions.value.kaspi || 0 : item.prepaid.kaspi || 0}/>&nbsp;
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{backgroundColor: 'rgba(0,0,0,0.1)', zIndex: 99999, margin: '-10px 0px', top: 0, display: 'flex', flexDirection: 'column', gap: 0}}>
                                                        <div onClick={() => startEdit(item.track_number, item.prepaid, 'prepaid')} style={{display: 'flex', alignItems: 'center', padding: "10px 0px", backgroundColor: 'white', justifyContent: 'space-between', gap: 30}}>
                                                        &nbsp;В Обшем:&nbsp;<p style={{width: '100%', textAlign: 'right'}}>{item.prepaid.cash + item.prepaid.bank_card + item.prepaid.transfer + item.prepaid.kaspi}&nbsp;</p>
                                                        </div> 
                                                    </div>
                                                }
                                            </td>
                                        }
                                        {(user.permissions.available_parameters.includes('paid')) && 
                                            <td className="TableDiv" >
                                                {
                                                    (selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter === 'paid') ?
                                                    <div style={{backgroundColor: 'rgba(0,0,0,0.1)', zIndex: 99999, margin: '-10px 0px', top: 0, display: 'flex', flexDirection: 'column', gap: 0}}>
                                                        <div onClick={() => startEdit(item.track_number, item.paid, 'paid')} style={{display: 'flex', alignItems: 'center', padding: "10px 0px", backgroundColor: 'white', justifyContent: 'space-between', gap: 30}}>
                                                        &nbsp;В Обшем:&nbsp;<p style={{width: '100%', textAlign: 'right'}}>{item.paid.cash + item.paid.bank_card + item.paid.transfer + item.paid.kaspi}&nbsp;</p>
                                                        </div>      
                                                        <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", backgroundColor: 'white', justifyContent: 'space-between'}}>
                                                        &nbsp;Нал:&nbsp;<input style={{textAlign: 'right', width: '100%'}} type="text" pattern="\d*" onBlur={async() => await submitEdit(item.track_number, 'paid', JSON.stringify(selectedEditOptions.value), JSON.stringify(item.paid))} onChange={(e) => startEdit(item.track_number, {cash: parseFloat(e.target.value), bank_card: item.paid.bank_card, transfer: item.paid.transfer, kaspi: item.paid.kaspi}, 'paid')} onFocus={() => startEdit(item.track_number, item.paid, 'paid')} placeholder="Наличные" className="ParameterInput" value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'paid' ? selectedEditOptions.value.cash || 0 : item.paid.cash || 0}/>&nbsp;
                                                        </div>
                                                        <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", backgroundColor: 'white', justifyContent: 'space-between', marginTop: 1}}>
                                                        &nbsp;Карта:&nbsp;<input style={{textAlign: 'right', width: '100%'}} type="text" pattern="\d*" onBlur={async() => await submitEdit(item.track_number, 'paid', JSON.stringify(selectedEditOptions.value), JSON.stringify(item.paid))} onChange={(e) => startEdit(item.track_number, {cash: item.paid.cash, bank_card: parseFloat(e.target.value), transfer: item.paid.transfer, kaspi: item.paid.kaspi}, 'paid')} onFocus={() => startEdit(item.track_number, item.paid, 'paid')} placeholder="Банк. карта" className="ParameterInput" value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'paid' ? selectedEditOptions.value.bank_card || 0 : item.paid.bank_card || 0}/>&nbsp;
                                                        </div>
                                                        <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", backgroundColor: 'white', justifyContent: 'space-between', marginTop: 1}}>
                                                        &nbsp;Перевод:&nbsp;<input style={{textAlign: 'right', width: '100%'}} type="text" pattern="\d*" onBlur={async() => await submitEdit(item.track_number, 'paid', JSON.stringify(selectedEditOptions.value), JSON.stringify(item.paid))} onChange={(e) => startEdit(item.track_number, {cash: item.paid.cash, bank_card: item.paid.bank_card, transfer: parseFloat(e.target.value), kaspi: item.paid.kaspi}, 'paid')} onFocus={() => startEdit(item.track_number, item.paid, 'paid')} placeholder="Перевод" className="ParameterInput" value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'paid' ? selectedEditOptions.value.transfer || 0 : item.paid.transfer || 0}/>&nbsp;
                                                        </div>
                                                        <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", backgroundColor: 'white', justifyContent: 'space-between', marginTop: 1}}>
                                                        &nbsp;Карта&nbsp;:<input style={{textAlign: 'right', width: '100%'}} type="text" pattern="\d*" onBlur={async() => await submitEdit(item.track_number, 'paid', JSON.stringify(selectedEditOptions.value), JSON.stringify(item.paid))} onChange={(e) => startEdit(item.track_number, {cash: item.paid.cash, bank_card: item.paid.bank_card, transfer: item.paid.transfer, kaspi: parseFloat(e.target.value)}, 'paid')} onFocus={() => startEdit(item.track_number, item.paid, 'paid')} placeholder="Каспи" className="ParameterInput" value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'paid' ? selectedEditOptions.value.kaspi || 0 : item.paid.kaspi || 0}/>&nbsp;
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{backgroundColor: 'rgba(0,0,0,0.1)', zIndex: 99999, margin: '-10px 0px', top: 0, display: 'flex', flexDirection: 'column', gap: 0}}>
                                                        <div onClick={() => startEdit(item.track_number, item.paid, 'paid')} style={{display: 'flex', alignItems: 'center', padding: "10px 0px", backgroundColor: 'white', justifyContent: 'space-between', gap: 30}}>
                                                        &nbsp;В Обшем:&nbsp;<p style={{width: '100%', textAlign: 'right'}}>{item.paid.cash + item.paid.bank_card + item.paid.transfer + item.paid.kaspi}&nbsp;</p>
                                                        </div> 
                                                    </div>
                                                }
                                            </td>
                                        }
                                        {user.permissions.available_parameters.includes('comment') && 
                                            <td className="TableDiv">
                                                <p style={{display: 'flex'}}>
                                                    <input onBlur={async() => await submitEdit(item.track_number, 'comment', selectedEditOptions.value, item.comment)} onFocus={() => startEdit(item.track_number, item.comment, 'comment')} type="text" className="ParameterInput" size={ selectedEditOptions.parameter == 'comment' ? Math.max(selectedEditOptions.value?.length, 20) : 20} onChange={(e) => startEdit(item.track_number, e.target.value, 'comment')} value={selectedEditOptions.track_number === item.track_number && selectedEditOptions.parameter == 'comment' ? selectedEditOptions.value : item.comment}/>
                                                </p>
                                            </td> 
                                        }                    
                                        {user.permissions.available_parameters.includes('courier_id') && 
                                            <td className="TableDiv">
                                                <select onChange={async(e) => await submitEdit(item.track_number, 'courier_id', e.target.value, item.courier_id)} value={item.courier_id} className="ParameterInput">
                                                    <option value={null}>Не задано</option>
                                                    {
                                                        couriers.map((courier, index) => (
                                                            <option key={courier.full_name} value={courier.id}>{courier.full_name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </td>
                                        }
                                        {user.permissions.available_parameters.includes('updated_at') && <td className="TableDiv">{item.updated_at}</td>}
                                        {user.permissions.available_parameters.includes('updated_by_id') && <td className="TableDiv">{item.updated_by}</td>}
                                        {user.permissions.available_parameters.includes('created_at') && <td className="TableDiv">{item.created_at}</td>}
                                    </tr>
                                    {
                                        selectedTrackNumberHistory === item.track_number &&
                                        item.history?.map((history_item, index) => (
                                            <tr className="TableRow History" key={"history" + index.toString()} >
                                                <td className="TableDiv"></td>
                                                <td className="TableDiv">{history_item.track_number}</td>
                                                {user.permissions.available_parameters.includes('reciever') && <td className="TableDiv">{history_item.reciever}</td>}
                                                {user.permissions.available_parameters.includes('phone') && <td className="TableDiv">{history_item.phone}</td>}
                                                {user.permissions.available_parameters.includes('transport') && <td className="TableDiv">{history_item.transport}</td>}
                                                {user.permissions.available_parameters.includes('storage_id') && <td className="TableDiv">{storages.find(storage => storage.id == history_item.storage_id)?.title || "Нет данных"}</td>}                                             
                                                {user.permissions.available_parameters.includes('count') && <td className="TableDiv">{history_item.count}</td>}
                                                {user.permissions.available_parameters.includes('status_id') && <td className="TableDiv">{statuses.find(status => status.id == history_item.status_id)?.name || "Нат данных"}</td>} 
                                                {user.permissions.available_parameters.includes('price') && <td className="TableDiv">₸&nbsp;{history_item.price}</td>}
                                                {user.permissions.available_parameters.includes('debt') && <td className="TableDiv">₸&nbsp;{history_item.debt}</td>}
                                                {user.permissions.available_parameters.includes('prepaid') && 
                                                    <td className="TableDiv" >
                                                        <div style={{zIndex: 99999, margin: '-10px 0px', top: 0, display: 'flex', flexDirection: 'column', gap: 0}}>
                                                            <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", justifyContent: 'space-between', gap: 30}}>
                                                            &nbsp;В Обшем:&nbsp;<p style={{width: '100%', textAlign: 'right'}}>{history_item.prepaid.cash + history_item.prepaid.bank_card + history_item.prepaid.transfer + history_item.prepaid.kaspi}&nbsp;</p>
                                                            </div> 
                                                        </div>
                                                    </td>       
                                                }                                    
                                                {user.permissions.available_parameters.includes('paid') && 
                                                    <td className="TableDiv" >
                                                        <div style={{zIndex: 99999, margin: '-10px 0px', top: 0, display: 'flex', flexDirection: 'column', gap: 0}}>
                                                            <div style={{display: 'flex', alignItems: 'center', padding: "10px 0px", justifyContent: 'space-between', gap: 30}}>
                                                            &nbsp;В Обшем:&nbsp;<p style={{width: '100%', textAlign: 'right'}}>{history_item.paid.cash + history_item.paid.bank_card + history_item.paid.transfer + history_item.paid.kaspi}&nbsp;</p>
                                                            </div> 
                                                        </div>
                                                    </td>         
                                                } 
                                                {user.permissions.available_parameters.includes('comment') && <td className="TableDiv">{history_item.comment}</td>}
                                                {user.permissions.available_parameters.includes('courier_id') && <td className="TableDiv">{couriers.find(courier => courier.id == history_item.courier_id)?.full_name || "Не задано"}</td>}
                                                {user.permissions.available_parameters.includes('updated_at') && <td className="TableDiv">{history_item.updated_at}</td>}
                                                {user.permissions.available_parameters.includes('updated_by_id') && <td className="TableDiv">{history_item.updated_by}</td>}
                                                {user.permissions.available_parameters.includes('created_at') && <td className="TableDiv">{history_item.created_at}</td>}
                                            </tr>
                                        ))
                                    }
                                </Fragment>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Cargo
import './Employees.css'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

function Employees({ sidebarOpen, setSidebarOpen, user, setUser }){

    const navigate = useNavigate()
    const [employees, setEmployees] = useState([])

    useEffect(() => {
        const username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');

        if(username && hash){

            // get user
            axios.get('https://loraandrey.kz/api/me.php', {
                params: {
                    username: username,
                    hash: hash,
                }
            }).then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setUser(data.user)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

            // get employees
            axios.get('https://loraandrey.kz/api/get_employees.php', {
                params: {
                    username: username,
                    hash: hash,
                }
            }).then(response => {
                const data = response.data;
    
                if (data.status === 'success') {
                    setEmployees(data.users)
                } else {
                    navigate('/auth')
                }
            })
            .catch(error => {
                navigate('/auth')
            });

        }else {
            navigate('/auth')
        }
    }, [])

    const delete_user = (user_id) => {
        const username = localStorage.getItem('username');
        const hash = localStorage.getItem('hash');

        const confirmDelete = window.confirm(`Вы уверены, что хотите удалить сотрудника с идентификатором: ${user_id}?`);

        if(username && hash){
            if(confirmDelete){
                axios.get('https://loraandrey.kz/api/delete_user.php', {
                    params: {
                        username: username,
                        hash: hash,
                        user_id: user_id
                    }
                }).then(response => {
                    const data = response.data;
        
                    if (data.status === 'success') {
                        setEmployees(data.users)
                    } else {
                        navigate('/auth')
                    }
                })
                .catch(error => {
                    navigate('/auth')
                });
            }
        }else {
            navigate('/auth')
        }
    }

    return(
        <div className={sidebarOpen ? "CargoContainer" : "CargoContainer fullwidth"}>
            <div className="Header">
                <div className="HeaderTitleAndButtons">
                    <div className="HeaderLeft">
                        {!sidebarOpen && 
                            <button onClick={() => setSidebarOpen(!sidebarOpen)} className="SidebarButton">
                                <svg style={{marginLeft: '-8px'}} width="24px" height="24px" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 7C4 6.44771 4.44772 6 5 6H24C24.5523 6 25 6.44771 25 7C25 7.55229 24.5523 8 24 8H5C4.44772 8 4 7.55229 4 7Z" fill="#000000"/><path d="M4 13.9998C4 13.4475 4.44772 12.9997 5 12.9997L16 13C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15L5 14.9998C4.44772 14.9998 4 14.552 4 13.9998Z" fill="#000000"/><path d="M5 19.9998C4.44772 19.9998 4 20.4475 4 20.9998C4 21.552 4.44772 21.9997 5 21.9997H22C22.5523 21.9997 23 21.552 23 20.9998C23 20.4475 22.5523 19.9998 22 19.9998H5Z" fill="#000000"/>
                                </svg>
                            </button>
                        }
                        <h2 className="HeaderTitle">Сотрудники</h2>
                    </div>
                </div>
            </div>
            <div className="Employees">
                <div className='EmployeesTable'>
                    <table style={{width: '100%', borderCollapse: 'collapse', marginTop: '10px'}}>
                        <thead>
                            <tr className="TableRow">
                                <th style={{width: 48}} className="TableHeader">&nbsp;</th>
                                <th className="TableHeader">id</th>
                                <th className="TableHeader">ФИО</th>
                                <th className="TableHeader">Логин</th>
                                <th className="TableHeader">Роль</th>
                                <th className="TableHeader">Статус</th>
                                <th className="TableHeader">Создано</th>
                            </tr>
                        </thead>
                        <tbody>
                                {
                                employees.map((employee, index) => (
                                    <tr className="TableRow">
                                        <td style={{maxWidth: '100%'}} className="TableDiv">
                                            <button onClick={() => delete_user(employee.id)} style={{backgroundColor: 'white', border: 'none', cursor: 'pointer'}}>
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 12V17" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M14 12V17" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M4 7H20" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                            <button onClick={() => navigate('/admin/employees/edit/' + employee.id )} style={{backgroundColor: 'white', border: 'none', cursor: 'pointer'}}>
                                                <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V12M9 15V12.5L17.75 3.75C18.4404 3.05964 19.5596 3.05964 20.25 3.75V3.75C20.9404 4.44036 20.9404 5.55964 20.25 6.25L15.5 11L11.5 15H9Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                        </td>
                                        <td className="TableDiv">{employee.id}</td>
                                        <td className="TableDiv">{employee.full_name}</td>
                                        <td className="TableDiv">{employee.username}</td>
                                        <td className="TableDiv">{employee.role}</td>
                                        <td className="TableDiv">{employee.status}</td>
                                        <td className="TableDiv">{employee.created_at}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Employees